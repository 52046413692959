import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import { listProductDetails } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const ProductPage = () => {
  const { id } = useParams(); // Get the product ID from the URL
  const dispatch = useDispatch();

  // Access the product details state
  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  useEffect(() => {
    // Dispatch action to fetch product details
    dispatch(listProductDetails(id));
  }, [dispatch, id]);

  return (
    <div className="container">
      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <>
          <Helmet>
            <title>{`${product.name} - Buy Now | Silent Exchange`}</title>
            <meta
              name="description"
              content={`Purchase ${product.name} for $${product.price}. Available now at Silent Exchange!`}
            />
            <meta property="og:title" content={product.name} />
            <meta
              property="og:description"
              content={`Get ${product.name} and expand your collection today!`}
            />
            <meta property="og:image" content={product.image} />
          </Helmet>
          <div className="product-details">
            <h1>{product.name}</h1>
            <p>Price: ${product.price}</p>
            <img src={product.image} alt={product.name} className="img-fluid" />
            <p>{product.description}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ProductPage;
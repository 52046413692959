// AutoLogout.js
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import { logout } from "../Redux/Actions/userActions"; // Adjust the import path as necessary

const AutoLogout = ({ children }) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    let timer;
    if (userInfo && userInfo.token) {
      try {
        const decodedToken = jwtDecode(userInfo.token);
        // Convert expiration time (in seconds) to milliseconds
        const expirationTime = decodedToken.exp * 1000;
        const currentTime = Date.now();
        const remainingTime = expirationTime - currentTime;

        if (remainingTime <= 0) {
          // If token is already expired, log out immediately.
          dispatch(logout());
        } else {
          // Set a timeout to log out when the token expires.
          timer = setTimeout(() => {
            dispatch(logout());
          }, remainingTime);
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        dispatch(logout());
      }
    }
    // Cleanup the timer if the component unmounts or userInfo changes.
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [dispatch, userInfo]);

  return <>{children}</>;
};

export default AutoLogout;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createOrder } from "../Redux/Actions/OrderActions";
import { ORDER_CREATE_RESET } from "../Redux/Constants/OrderConstants";
import Header from "./../components/Header";
import Message from "./../components/LoadingError/Error";
import RateDropdown from "../components/ShippoRateDropDown/RateDropdown";
import axios from "axios";
import URL from "../Redux/Url";
import "../App.css";
import { toast } from "react-toastify";

const ToastObjects = {
  pauseOnFocusLoss: false,
  draggable: false,
  pauseOnHover: false,
  autoClose: 2000,
};

const PlaceOrderScreen = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const navigate = useNavigate();

  const [rates, setRates] = useState([]);
  const [selectedRate, setSelectedRate] = useState(null);


  // Calculate Prices
 // Calculate Prices
 const calculatePrices = () => {
  const itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0);
  const shippingPrice = selectedRate ? parseFloat(selectedRate.amount) : 0;
  const taxPrice = Number((0.15 * itemsPrice).toFixed(2));
  const totalPrice = (itemsPrice + shippingPrice + taxPrice).toFixed(2);

  return { itemsPrice, shippingPrice, taxPrice, totalPrice };
};


  const { itemsPrice, shippingPrice, taxPrice, totalPrice } = calculatePrices();

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success } = orderCreate;

  useEffect(() => {
    if (success) {
      navigate(`/order/${order._id}`);
      dispatch({ type: ORDER_CREATE_RESET });
    }
  }, [navigate, dispatch, success, order]);

  const placeOrderHandler = () => {
    try {
      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          paymentMethod: cart.paymentMethod,
          itemsPrice,
          shippingPrice,
          taxPrice,
          totalPrice,
          rate: selectedRate,
          addressTo: {
            name: userInfo.name || '',
            street1: cart.shippingAddress.street || '',
            street2: cart.shippingAddress.street2 || '',
            street3: cart.shippingAddress.street3 || '',
            city: cart.shippingAddress.city || '',
            state: cart.shippingAddress.state || '',
            zip: cart.shippingAddress.zipcode || '',
            country: cart.shippingAddress.country || '',
            phone: "9091234567",
            email: userInfo.email || ''
          }
        })
      );
    } catch (error) {
      console.error("Error placing order:", error.message);
    }
  };

  const fetchRates = async (dimensions, user, toAddress) => {
    try {
      const Parcel = {
        length: parseFloat(dimensions.length) || 0,
        width: parseFloat(dimensions.width) || 0,
        height: parseFloat(dimensions.height) || 0,
        distanceUnit: dimensions.distance_unit || 'in',
        weight: parseFloat(dimensions.weight) || 0,
        massUnit: dimensions.mass_unit || 'lb'
      };
      const addressTo = {
        name: user.name || '',
        street1: toAddress.street1 || '',
        street2: toAddress.street2 || '',
        street3: toAddress.street3 || '',
        city: toAddress.city || '',
        state: toAddress.state || '',
        zip: toAddress.zipcode || '',
        country: toAddress.country || '',
        phone: toAddress.phone || '9091234567',
        email: user.email || ''
      };

      const response = await axios.post(`${URL}/api/shipment`, { dimensions: Parcel, toAddress: addressTo });
      const fetchedRates = response.data.rates;
      setRates(fetchedRates);

    } catch (error) {
      console.error('Error fetching rates:', error);
      toast.error("Failed to fetch rates", ToastObjects);
    }
  };

  const handleFetchRates = () => {
    if (cart.cartItems.length > 0) {
      const firstItemDimensions = cart.cartItems[0].dimensions;
      const addressTo = {
        street1: cart.shippingAddress.street || '',
        street2: cart.shippingAddress.street2 || '',
        street3: cart.shippingAddress.street3 || '',
        city: cart.shippingAddress.city || '',
        state: cart.shippingAddress.state || '',
        zipcode: cart.shippingAddress.zipcode || '',
        country: cart.shippingAddress.country || '',
      };
      fetchRates(firstItemDimensions, userInfo, addressTo);
    } else {
      console.error('Cart is empty or no dimensions available');
    }
  };

  const handleRateSelect = (rate) => {
    setSelectedRate(rate); // Set the selected rate and save its amount to the shippingPrice
  };

  // const handleCustomShippingChange = (e) => {
  //   setCustomShipping(e.target.value); // Update custom shipping state
  // };

  return (
    <>
      <Header />
      <div className="container">
        <div className="row order-detail">
          {/* Customer Information */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className="alert-success order-box">
                  <i className="fas fa-user"></i>
                </div>
              </div>
              <div className="col-md-8 center">
                <h5><strong>Customer</strong></h5>
                <p>{userInfo.name}</p>
                <p>{userInfo.email}</p>
              </div>
            </div>
          </div>

          {/* Order Information */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className="alert-success order-box">
                  <i className="fas fa-truck-moving"></i>
                </div>
              </div>
              <div className="col-md-8 center">
                <h5><strong>Order info</strong></h5>
                <p>Shipping: {cart.shippingAddress.country}</p>
                <p>Payment method: {cart.paymentMethod}</p>
              </div>
            </div>
          </div>

          {/* Delivery Information */}
          <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
            <div className="row">
              <div className="col-md-4 center">
                <div className="alert-success order-box">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
              </div>
              <div className="col-md-8 center">
                <h5><strong>Deliver to</strong></h5>
                <p>Address: {cart.shippingAddress.city}, {cart.shippingAddress.state} {cart.shippingAddress.street}, {cart.shippingAddress.zipcode}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Order Products */}
        <div className="row order-products justify-content-between">
          <div className="col-lg-8">
            {cart.cartItems.length === 0 ? (
              <Message variant="alert-info mt-5">Your cart is empty</Message>
            ) : (
              cart.cartItems.map((item, index) => (
                <div className="order-product row" key={index}>
                  <div className="col-md-3 col-6">
                    <img src={item.image} alt={item.name} />
                  </div>
                  <div className="col-md-5 col-6 d-flex align-items-center">
                    <Link to={`/products/${item.product}`}>
                      <h6>{item.name}</h6>
                    </Link>
                  </div>
                  <div className="mt-3 mt-md-0 col-md-2 col-6 d-flex align-items-center flex-column justify-content-center">
                    <h4>QUANTITY</h4>
                    <h6>{item.qty}</h6>
                  </div>
                  <div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end d-flex flex-column justify-content-center">
                    <h4>SUBTOTAL</h4>
                    <h6>${item.qty * item.price}</h6>
                  </div>
                </div>
              ))
            )}
          </div>
          {/* Shipping and Total */}
          <div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td><strong>Products</strong></td>
                  <td>${itemsPrice}</td>
                </tr>
                <tr>
                  <td><strong>Shipping</strong></td>
                  <td>${shippingPrice}</td>
                </tr>
                <tr>
                  <td><strong>Tax</strong></td>
                  <td>${taxPrice}</td>
                </tr>
                <tr>
                  <td><strong>Total</strong></td>
                  <td>${totalPrice}</td>
                </tr>
              </tbody>
            </table>
            {cart.cartItems.length > 0 && (
 <>
 { selectedRate && (
   <button
     type="submit"
     onClick={placeOrderHandler}
     style={{ margin: "10px 0" }}
   >
     PLACE ORDER
   </button>
 )}
 <button onClick={handleFetchRates} style={{ margin: "10px 0" }}>
   Fetch Rates
 </button>
 <button style={{ margin: "10px 0" }}>
   <RateDropdown rates={rates} onRateSelect={handleRateSelect} />
 </button>
</>
)}



          </div>
        </div>


        </div>



    </>
  );
};

export default PlaceOrderScreen;

import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Rating from "./Rating";
import Pagination from "./pagination";
import { useDispatch, useSelector } from "react-redux";
import { listProduct } from "../../Redux/Actions/ProductActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";

const ShopSection = () => {
  const dispatch = useDispatch();
  const { keyword = "", pageNumber = "1" } = useParams(); // Ensure pageNumber defaults to "1"

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    // Dispatch action to fetch products
    dispatch(listProduct(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <div className="container">
      <div className="section">
        <div className="row">
          <div className="col-lg-12 col-md-12 article">
            <div className="shopcontainer row">
              {/* Handle loading, error, and product display */}
              {loading ? (
                <div className="mb-5">
                  <Loading />
                </div>
              ) : error ? (
                <Message variant="alert-danger">{error}</Message>
              ) : (
                products.map((product) => (
                  <div
                    className="shop col-lg-4 col-md-6 col-sm-6"
                    key={product._id}
                  >
                    <div className="border-product">
                      <Link to={`/products/${product._id}`}>
                        <div className="shopBack">
                          <img src={product.image} alt={product.name} />
                        </div>
                      </Link>
                      <div className="shoptext">
                        <p>
                          <Link to={`/products/${product._id}`}>
                            {product.name}
                          </Link>
                        </p>
                        <Rating
                          value={product.rating}
                          text={`${product.numReviews} reviews`}
                        />
                        {product.countInStock > 0 ? (
                          <h3>Pick Shipping</h3>
                        ) : (
                          <h3>Unavailable</h3>
                        )}
                        <h3>${product.price}</h3>
                      </div>
                    </div>
                  </div>
                ))
              )}

              {/* Pagination Component */}
              {!loading && pages > 1 && (
                <Pagination pages={pages} page={page} keyword={keyword} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopSection;

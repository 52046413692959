import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import { appId, locationId } from "./Ids.js";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetails, payOrder } from "../Redux/Actions/OrderActions";
import Loading from "./../components/LoadingError/Loading";
import Message from "./../components/LoadingError/Error";
import { Link, useParams } from "react-router-dom";
import { ORDER_PAY_RESET } from "../Redux/Constants/OrderConstants";
import URL from "../Redux/Url";
import "../App.css";

const OrderScreen = () => {
  const [sdkReady, setSdkReady] = useState(false);
  const [payments, setPayments] = useState(null);
  const [card, setCard] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { id: orderId } = useParams();
  const dispatch = useDispatch();
  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;
  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  useEffect(() => {
    const initializeSquare = async () => {
      try {
        if (!window.Square) {
          await new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = 'https://web.squarecdn.com/v1/square.js';
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
          });
        }
        const square = window.Square;
        const payments = square.payments(appId, locationId);
        setPayments(payments);
        setSdkReady(true);
      } catch (error) {
        console.error("Error initializing Square:", error);
      }
    };

    initializeSquare();
  }, []);

  useEffect(() => {
    if (!order || successPay || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, orderId, order, successPay]);

  useEffect(() => {
    const initializeCard = async () => {
      try {
        if (sdkReady && payments && !card) {
          const cardContainer = document.getElementById("card-container");
          if (cardContainer) {
            const initializedCard = await payments.card();
            await initializedCard.attach("#card-container");
            setCard(initializedCard);
          } else {
            setTimeout(initializeCard, 100);
          }
        }
      } catch (error) {
        console.error("Error initializing card:", error);
      }
    };

    initializeCard();
  }, [sdkReady, payments, card]);

  const handlePaymentMethodSubmission = async () => {
    setButtonDisabled(true);
    setIsProcessing(true);

    try {
      const token = await tokenize(card);
      const verificationToken = await verifyBuyer(payments, token);
      const amountInCents = order ? Math.round(order.totalPrice * 100) : 0;
      const paymentResults = await createPayment(token, verificationToken, amountInCents, order);

      // Dispatch the payment action and update the states
      dispatch(payOrder(orderId, paymentResults, true));
      setIsProcessing(false);
      setIsPaid(true);
      displayPaymentResults("SUCCESS");
    } catch (e) {
      setButtonDisabled(false);
      setIsProcessing(false);
      displayPaymentResults("FAILURE");
      const statusContainer = document.getElementById("payment-status-container");
      statusContainer.innerText = "Payment Error. Please check your payment details or use a different payment method.";
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure", "text-white");
      statusContainer.style.visibility = "visible";
      setTimeout(() => {
        statusContainer.style.visibility = "hidden";
      }, 3000);
    }
  };

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === "OK") {
      return tokenResult.token;
    } else {
      const errorMessage = `Tokenization failed with status: ${tokenResult.status}` +
                           (tokenResult.errors ? ` and errors: ${JSON.stringify(tokenResult.errors)}` : '');
      throw new Error(errorMessage);
    }
  };

  const verifyBuyer = async (payments, token) => {
    const verificationDetails = {
      amount: order.totalPrice.toFixed(2),
      billingContact: {
        givenName: order.user.name,
        email_address: order.user.email,
        addressLines: [order.shippingAddress.street],
        city: order.shippingAddress.city,
        state: order.shippingAddress.state,
        countryCode: order.shippingAddress.country,
      },
      currencyCode: "USD",
      intent: "CHARGE",
    };
    const verificationResults = await payments.verifyBuyer(token, verificationDetails);
    return verificationResults.token;
  };

  const createPayment = async (token, verificationToken, amountInCents, order) => {
    const body = JSON.stringify({
      locationId,
      sourceId: token,
      verificationToken,
      idempotencyKey: window.crypto.randomUUID(),
      givenName: order.user.name,
      amount: amountInCents,
      email: order.user.email,
      order,
      shippingAddress: {
        address_line_1: order.shippingAddress.street,
        locality: order.shippingAddress.city,
        administrative_district_level_1: order.shippingAddress.state,
        postal_code: order.shippingAddress.zipcode,
        country: order.shippingAddress.country,
        first_name: order.user.name,
      },
    });

    const paymentResponse = await fetch(`${URL}/api/payment`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body,
    });

    if (!paymentResponse.ok) {
      const errorBody = await paymentResponse.text();
      console.error("Payment Error:", errorBody);
      throw new Error("Payment request failed");
    }
    return paymentResponse.json();
  };

  const displayPaymentResults = (status) => {
    const statusContainer = document.getElementById("payment-status-container");
    if (status === "SUCCESS") {
      statusContainer.classList.remove("is-failure");
      statusContainer.classList.add("is-success");
    } else {
      statusContainer.classList.remove("is-success");
      statusContainer.classList.add("is-failure");
    }
    statusContainer.style.visibility = "visible";
  };

  return (
    <>
      <Header />

      <div className="container">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div className="row order-detail">
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-user"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Customer</strong>
                    </h5>
                    <p>{order.user.name}</p>
                    <p>
                      <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-truck-moving"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Order info</strong>
                    </h5>
                    <p>Shipping: {order.shippingAddress.country}</p>
                    <p>Pay method: {order.paymentMethod}</p>
                    {order.isPaid ? (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Paid on {moment(order.paidAt).calendar()}
                        </p>
                      </div>
                    ) : (
                      <div className="bg-danger p-2 col-12">
                        <p className="text-white text-center text-sm-start">Not Paid</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-4 mb-lg-4 mb-5 mb-sm-0">
                <div className="row">
                  <div className="col-md-4 center">
                    <div className="alert-success order-box">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </div>
                  <div className="col-md-8 center">
                    <h5>
                      <strong>Deliver to</strong>
                    </h5>
                    <p>
                      Address: {order.shippingAddress.city}, {order.shippingAddress.state},{" "}
                      {order.shippingAddress.street}, {order.shippingAddress.zipcode}
                    </p>
                    {order.isDelivered ? (
                      <div className="bg-info p-2 col-12">
                        <p className="text-white text-center text-sm-start">
                          Delivered on {moment(order.deliveredAt).calendar()}
                        </p>
                      </div>
                    ) : (
                      <div className="bg-danger p-2 col-12">
                        <p className="text-white text-center text-sm-start">Not Delivered</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row order-products justify-content-between">
              <div className="col-lg-8">
                {order.orderItems.length === 0 ? (
                  <Message variant="alert-info mt-5">Your order is empty</Message>
                ) : (
                  <>
                    {order.orderItems.map((item, index) => (
                      <div className="order-product row" key={index}>
                        <div className="col-md-3 col-6">
                          <img src={item.image} alt={item.name} />
                        </div>
                        <div className="col-md-5 col-6 d-flex align-items-center">
                          <Link to={`/products/${item.product}`}>
                            <h6>{item.name}</h6>
                          </Link>
                        </div>
                        <div className="mt-3 mt-md-0 col-md-2 col-6 d-flex align-items-center flex-column justify-content-center ">
                          <h4>QUANTITY</h4>
                          <h6>{item.qty}</h6>
                        </div>
                        <div className="mt-3 mt-md-0 col-md-2 col-6 align-items-end d-flex flex-column justify-content-center ">
                          <h4>SUBTOTAL</h4>
                          <h6>${item.qty * item.price}</h6>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
              
              <div className="col-lg-3 d-flex align-items-end flex-column mt-5 subtotal-order">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Products</strong>
                      </td>
                      
                      <td>${order.itemsPrice}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Shipping</strong>
                      </td>
                      <td>${order.shippingPrice}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tax</strong>
                      </td>
                      <td>${order.taxPrice}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>${order.totalPrice}</td>
                    </tr>
                  </tbody>
                </table>
                {!order.isPaid && (
                  <div className="col-12">
                    {loadingPay && <Loading />}
                    {!sdkReady ? (
                      <Loading />
                    ) : (
                      <div>
                        <form id="payment-form">
                          <div id="card-container"></div>
                          <button
                            id="card-button"
                            type="button"
                            onClick={handlePaymentMethodSubmission}
                            disabled={isButtonDisabled || isPaid}
                          >
                            {isProcessing ? "Processing..." : isPaid ? "Paid" : `Pay ${order.totalPrice}`}
                          </button>
                        </form>
                        <div id="payment-status-container"></div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default OrderScreen;

import React from "react";

const RateDropdown = ({ rates, onRateSelect }) => {
  const handleRateChange = (event) => {
    const selectedRate = rates.find(rate => rate.object_id === event.target.value);
    onRateSelect(selectedRate);
  };

  return (
    <div>
      <label>shipping rate:</label>
      <select onChange={handleRateChange}>
        <option value="">--Select a rate--</option>
        {rates.map((rate) => (
          <option key={rate.object_id} value={rate.object_id}>
            {rate.provider} - {rate.servicelevel.name} - ${rate.amount}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RateDropdown;

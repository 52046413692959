import React from "react";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "./screens/Register";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFound from "./screens/NotFound";
import PrivateRoutes from "./PrivateRoutes";
import ShopSection from "./components/homeComponents/ShopSection";
import ProductPage from "./components/homeComponents/ProductPage";
import ReactGA from "react-ga4";

// Import AutoLogout component
import AutoLogout from "../src/components/AutoLogOut";

const App = () => {
  ReactGA.initialize("G-BSNFH26GSZ");

  return (
    <HelmetProvider>
      <div className="app">
        <Router>
          <AutoLogout>
            <Routes>
              <Route path="/" element={<HomeScreen />} />
              <Route path="/search/:keyword" element={<HomeScreen />} />
              <Route path="/page/:pageNumber" element={<HomeScreen />} />
              <Route path="/search/:keyword/page/:pageNumber" element={<HomeScreen />} />
              <Route path="/product/:id" element={<ProductPage />} />

              {/* Routes for ShopSection */}
              <Route path="/search/:keyword" element={<ShopSection />} />
              <Route path="/page/:pageNumber" element={<ShopSection />} />
              <Route path="/search/:keyword/page/:pageNumber" element={<ShopSection />} />

              <Route path="/products/:id" element={<SingleProduct />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />

              <Route element={<PrivateRoutes />}>
                <Route path="/profile" element={<ProfileScreen />} />
              </Route>

              <Route path="/cart/:id?" element={<CartScreen />} />
              <Route path="/login/shipping" element={<ShippingScreen />} />
              <Route path="/register/shipping" element={<ShippingScreen />} />
              <Route path="/payment" element={<PaymentScreen />} />
              <Route path="/placeorder" element={<PlaceOrderScreen />} />
              <Route path="/order/:id" element={<OrderScreen />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AutoLogout>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import { saveShippingAddress } from "../Redux/Actions/cartActions";
import { useNavigate } from "react-router-dom";
import URL from "../Redux/Url";

const usStates = [
  { name: 'ALABAMA', abbreviation: 'AL' },
  { name: 'ALASKA', abbreviation: 'AK' },
  { name: 'AMERICAN SAMOA', abbreviation: 'AS' },
  { name: 'ARIZONA', abbreviation: 'AZ' },
  { name: 'ARKANSAS', abbreviation: 'AR' },
  { name: 'CALIFORNIA', abbreviation: 'CA' },
  { name: 'COLORADO', abbreviation: 'CO' },
  { name: 'CONNECTICUT', abbreviation: 'CT' },
  { name: 'DELAWARE', abbreviation: 'DE' },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC' },
  { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM' },
  { name: 'FLORIDA', abbreviation: 'FL' },
  { name: 'GEORGIA', abbreviation: 'GA' },
  { name: 'GUAM', abbreviation: 'GU' },
  { name: 'HAWAII', abbreviation: 'HI' },
  { name: 'IDAHO', abbreviation: 'ID' },
  { name: 'ILLINOIS', abbreviation: 'IL' },
  { name: 'INDIANA', abbreviation: 'IN' },
  { name: 'IOWA', abbreviation: 'IA' },
  { name: 'KANSAS', abbreviation: 'KS' },
  { name: 'KENTUCKY', abbreviation: 'KY' },
  { name: 'LOUISIANA', abbreviation: 'LA' },
  { name: 'MAINE', abbreviation: 'ME' },
  { name: 'MARSHALL ISLANDS', abbreviation: 'MH' },
  { name: 'MARYLAND', abbreviation: 'MD' },
  { name: 'MASSACHUSETTS', abbreviation: 'MA' },
  { name: 'MICHIGAN', abbreviation: 'MI' },
  { name: 'MINNESOTA', abbreviation: 'MN' },
  { name: 'MISSISSIPPI', abbreviation: 'MS' },
  { name: 'MISSOURI', abbreviation: 'MO' },
  { name: 'MONTANA', abbreviation: 'MT' },
  { name: 'NEBRASKA', abbreviation: 'NE' },
  { name: 'NEVADA', abbreviation: 'NV' },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH' },
  { name: 'NEW JERSEY', abbreviation: 'NJ' },
  { name: 'NEW MEXICO', abbreviation: 'NM' },
  { name: 'NEW YORK', abbreviation: 'NY' },
  { name: 'NORTH CAROLINA', abbreviation: 'NC' },
  { name: 'NORTH DAKOTA', abbreviation: 'ND' },
  { name: 'OHIO', abbreviation: 'OH' },
  { name: 'OKLAHOMA', abbreviation: 'OK' },
  { name: 'OREGON', abbreviation: 'OR' },
  { name: 'PENNSYLVANIA', abbreviation: 'PA' },
  { name: 'PUERTO RICO', abbreviation: 'PR' },
  { name: 'RHODE ISLAND', abbreviation: 'RI' },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC' },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD' },
  { name: 'TENNESSEE', abbreviation: 'TN' },
  { name: 'TEXAS', abbreviation: 'TX' },
  { name: 'UTAH', abbreviation: 'UT' },
  { name: 'VERMONT', abbreviation: 'VT' },
  { name: 'VIRGINIA', abbreviation: 'VA' },
  { name: 'WASHINGTON', abbreviation: 'WA' },
  { name: 'WEST VIRGINIA', abbreviation: 'WV' },
  { name: 'WISCONSIN', abbreviation: 'WI' },
  { name: 'WYOMING', abbreviation: 'WY' }
];

// Function to convert state name to its abbreviation
const convertStateNameToAbbreviation = (stateName) => {
  const state = usStates.find(
    (state) =>
      state.name.toUpperCase() === stateName.toUpperCase() ||
      state.abbreviation.toUpperCase() === stateName.toUpperCase()
  );
  return state ? state.abbreviation : null;
};

const ShippingScreen = () => {
  window.scrollTo(0, 0);

  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [street, setStreet] = useState(shippingAddress.street || '');
  const [city, setCity] = useState(shippingAddress.city || '');
  const [state, setState] = useState(shippingAddress.state || '');
  const [zipcode, setZipcode] = useState(shippingAddress.zipcode || '');
  const [country, setCountry] = useState(shippingAddress.country || "US");
  const [validationResult, setValidationResult] = useState(null);
  const [stateError, setStateError] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleStateChange = (e) => {
    const inputState = e.target.value.toUpperCase();
    const abbreviatedState = convertStateNameToAbbreviation(inputState);

    if (abbreviatedState) {
      setState(abbreviatedState);
      setStateError(null);
    } else if (inputState === "") {
      setState("");
      setStateError(null);
    } else {
      setState(inputState);
      setStateError("Invalid state name or abbreviation");
    }
  };

  const handleCountryChange = (e) => {
    const inputCountry = e.target.value.toUpperCase();
    setCountry(inputCountry === "US" ? "US" : "US");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));

    if (!userInfo) {
      console.error('No user info found in local storage');
      return;
    }

    const apiKey = '5COLLE681A769';  // Replace with your USPS API key
    try {
      const response = await fetch(`https://secure.shippingapis.com/ShippingAPI.dll?API=Verify&XML=<AddressValidateRequest USERID='${apiKey}'>\
        <Address ID='0'>\
        <Address1>${street}</Address1>\
        <Address2></Address2>\
        <City>${city}</City>\
        <State>${state}</State>\
        <Zip5>${zipcode}</Zip5>\
        <Zip4></Zip4>\
        </Address>\
        </AddressValidateRequest>`);

      const data = await response.text();

      if (data.includes('<Error>')) {
        setValidationResult('Not a valid address');
      } else {
        const saveResponse = await fetch(`${URL}/api/shipping`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          'Authorization': `Bearer ${userInfo.token}`,
          body: JSON.stringify({
            userId: userInfo._id,
            street,
            city,
            state,
            zipcode,
            country
          })
        });

        if (!saveResponse.ok) throw new Error('Failed to save shipping address');

        dispatch(saveShippingAddress({userId: userInfo._id, street, city, state, zipcode, country }));
        navigate("/payment");
      }
    } catch (error) {
      console.error('Error validating address:', error);
    }
  };

  return (
    <>
      <Header />
      <div className="container d-flex justify-content-center align-items-center login-center">
        <form className="Login col-md-8 col-lg-4 col-11" onSubmit={submitHandler}>
          <h6>DELIVERY ADDRESS</h6>
          <input
            type="text"
            placeholder="Enter street"
            value={street}
            required
            onChange={(e) => setStreet(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter city"
            value={city}
            required
            onChange={(e) => setCity(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter state"
            value={state}
            required
            onChange={handleStateChange}
          />
          {stateError && <div className="error">{stateError}</div>}
          <input
            type="text"
            placeholder="Enter postal code"
            value={zipcode}
            required
            onChange={(e) => setZipcode(e.target.value)}
          />
          <input
            type="text"
            placeholder="Enter country"
            value={country}
            required
            onChange={handleCountryChange}
          />
          {validationResult && (
            <div className="error">{validationResult}</div>
          )}
          <button type="submit">Continue</button>
        </form>
      </div>
    </>
  );
};

export default ShippingScreen;

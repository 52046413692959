import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CategoryTabs.css";

const CategoryTabs = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Track the current location (URL)
  const tabsRef = useRef(null);
  const [visibleTabs, setVisibleTabs] = useState(5); // Start with 5 visible tabs
  const [activeTab, setActiveTab] = useState(null); // State to track the active tab

  // Memoize the categories array to avoid unnecessary re-renders
  const categories = useMemo(() => [
    { name: "Pokemon", keyword: "pokemon" },
    { name: "YuGiOh", keyword: "yugioh" },
    { name: "CGC", keyword: "CGC" },
    { name: "PSA", keyword: "PSA" },
    { name: "StarWars", keyword: "star wars" },
    { name: "TMNT", keyword: "TMNT" },

  ], []);

  // Handle click to navigate to search
  const handleTabClick = (keyword) => {
    setActiveTab(keyword); // Set the clicked tab as active
    navigate(`/search/${keyword}/page/1`);
  };

  // Adjust visible tabs based on window size
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;

      if (!isMobile) {
        setVisibleTabs(categories.length); // Show all tabs on desktop
      } else {
        const container = tabsRef.current;
        if (container) {
          const containerWidth = container.clientWidth;
          const tabWidth = 80; // Tab width (adjust based on your design)
          const maxTabs = Math.floor(containerWidth / tabWidth);
          setVisibleTabs(Math.min(maxTabs, categories.length));
        }
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial state

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [categories.length]); // Now `categories.length` is the only dependency

  // Set active tab based on the current location (URL) on initial load
  useEffect(() => {
    const pathParts = location.pathname.split("/");
    const currentKeyword = pathParts[2]; // Extract the keyword from the URL

    if (currentKeyword && categories.some((category) => category.keyword === currentKeyword)) {
      setActiveTab(currentKeyword); // Set active tab if keyword matches
    } else {
      setActiveTab(null); // Unselect all tabs if category doesn't exist
    }
  }, [location, categories]); // Now `categories` is memoized, so it won't trigger unnecessary re-renders

  return (
    <div className="category-tabs-container" ref={tabsRef}>
      <div className="category-tabs">
        {categories.slice(0, visibleTabs).map((category) => (
          <button
            key={category.keyword}
            className={`tab-button ${activeTab === category.keyword ? "active" : ""}`}
            onClick={() => handleTabClick(category.keyword)}
          >
            {category.name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CategoryTabs;
